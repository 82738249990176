import {mountLinkUrlOptions} from 'core/helpers/mountLinkUrl';
import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {selectCategoriesList} from 'modules/categories/selectors';
import {parseMenuLinks} from 'modules/menu/helpers';
import {actionSetMenuList} from 'modules/menu/slice';
import {TMenuListResponse} from 'modules/menu/types';

export const getMenuList: TAppThunk<void> = async (dispatch, getState, {menuService}) => {
    try {
        const {menus} = await dispatch(
            requestAction<TMenuListResponse>({
                requestCb: () => menuService.getMenuList(),
                type: actionSetMenuList.type,
            })
        );

        if (!menus) {
            return;
        }

        // Монтирование урлов, которые понимает клиент
        const categories = selectCategoriesList(getState());
        const options = mountLinkUrlOptions(categories);
        const menuList = parseMenuLinks(menus, options);

        dispatch(actionSetMenuList(menuList));
    } catch (e) {
        console.error(e);
    }
};
